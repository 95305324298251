import React, { useState, useEffect, useCallback } from 'react';
import { 
  DndContext, 
  useDraggable, 
  useDroppable,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Star } from 'lucide-react';
import './globals.css'

const P = {
  brot: {t: 30, b: 6, p: 3, c: 15, e: '🥖'},  
  breze: {t: 20, b: 15, p: 1, c: 12, e: '🥨'},
  kuchen: {t: 45, b: 1, p: 15, c: 9, e: '🎂'}  
};

const DraggableProduct = ({ type, amount, emoji, isDragging }) => {
  const {attributes, listeners, setNodeRef} = useDraggable({
    id: type,
    data: { type: 'PRODUCT', productType: type },
    disabled: amount <= 0
  });

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={`
        select-none 
        p-2 rounded border border-stone-200
        transition-colors touch-none
        ${amount > 0 
          ? 'cursor-grab hover:bg-stone-50 active:cursor-grabbing active:scale-95' + (isDragging ? ' opacity-0' : '')
          : 'cursor-not-allowed opacity-50'
        }
      `}
    >
      <div className="flex items-center gap-2">
        <span className="text-3xl">{emoji}</span>
      </div>
    </div>
  );
};

const DragPreview = ({ emoji }) => {
  return (
    <div className="bg-white shadow-lg rounded p-2 cursor-grabbing">
      <span className="text-3xl">{emoji}</span>
    </div>
  );
};

const DroppableCustomer = ({ customer, onServe, productData, currentStock, earned, ratingLost }) => {
  const {setNodeRef, isOver, active} = useDroppable({
    id: customer.id,
    data: { 
      wants: customer.wants,
      currentStock
    }
  });


  const isValidDrop = active ? 
    active.data.current?.productType === customer.wants && 
    currentStock[active.data.current?.productType]?.amount > 0 : 
    false;

    const content = (() => {
      if (customer.patience <= 0) {  
        return (
          <div className="flex items-center gap-1 bg-red-100 rounded p-2 w-16 h-[40px] justify-center shrink-0">
            <div className="text-sm text-red-600">-0.5</div><Star size={12} className="fill-red-100 text-red-600" />
          </div>
        );
      }
  
      if (earned) { 
        return (
          <div className="flex items-center gap-1 bg-green-100 rounded p-2 w-16 h-[40px] justify-center shrink-0">
            <span>{productData[customer.wants].e}</span>
            <div className="text-sm text-green-600">+${earned}</div>
          </div>
        );
      }

    return (
      <div
        ref={setNodeRef}
        className={`
          flex items-center gap-1 rounded p-2 w-16 h-[40px] justify-center shrink-0
          transition-colors duration-200
          ${isOver ? (isValidDrop ? 'bg-emerald-50 scale-105' : 'bg-rose-50 scale-105') : 
            customer.patience <= 3 ? 'bg-orange-200' : 
            customer.patience <= 6 ? 'bg-orange-100' : 
            'bg-stone-50'
          }
        `}
      >
        <span>{productData[customer.wants].e}</span>
        <div className="text-sm">{customer.patience}s</div>
      </div>
    );
  })();

  return content;
};

// Update Tooltip component styling
const Tooltip = ({ children, position, visible, onDismiss }) => {
  if (!visible) return null;
  
  return (
    <div className={`
      absolute z-50 p-4 rounded-lg shadow-lg
      bg-gradient-to-b from-stone-800 to-stone-900
      text-white
      max-w-[250px]
      text-sm
      animate-tooltip-enter
      ${position}
    `}>
      <div className="space-y-3">
        <div>{children}</div>
        <Button 
          onClick={onDismiss}
          className="w-full bg-white text-stone-800 hover:bg-stone-50 
                     border-2 border-white transition-colors"
          size="sm"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const BakeryGame = () => {
  const STARTING_BUDGET = 100;  // Add this constant at the top of the component
  const [money, setMoney] = useState(STARTING_BUDGET);
  const [time, setTime] = useState(2 * (300/24));
  const [day, setDay] = useState(1);
  const [firstDay, setFirstDay] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [rating, setRating] = useState(50); // Now using integers (50 = 5.0)
  const [waitingForStart, setWaitingForStart] = useState(false);
  const [products, setProducts] = useState({
    brot: { amount: 0, inProduction: 0, productionTimes: [] },
    kuchen: { amount: 0, inProduction: 0, productionTimes: [] },
    breze: { amount: 0, inProduction: 0, productionTimes: [] }
  });
  const [dailyStats, setDailyStats] = useState({
    satisfiedCustomers: 0,
    lostCustomers: 0,
    productionCosts: 0,
    revenue: 0,
    soldProducts: {
      brot: 0,
      kuchen: 0,
      breze: 0
    },
    wastedProducts: {
      brot: 0,
      kuchen: 0,
      breze: 0
    },
    initialRating: 5,
    initialBudget: STARTING_BUDGET
  }); // Initialize with default values instead of null
  const [gameOver, setGameOver] = useState(false);
  const [finalStats, setFinalStats] = useState(null);
  const [totalCustomersServed, setTotalCustomersServed] = useState(0);
  const [activeId, setActiveId] = useState(null);
  const [hasProduced, setHasProduced] = useState(false);
  const [hasServed, setHasServed] = useState(false);
  // Update tooltips state to include sequence
  const [tooltips, setTooltips] = useState(() => {
    const bakingSeen = !!localStorage.getItem('baking_tooltip_seen');
    const servingSeen = !!localStorage.getItem('serving_tooltip_seen');
    const ratingSeen = !!localStorage.getItem('rating_tooltip_seen');
    
    return {
      baking: {
        visible: !bakingSeen,
        completed: bakingSeen
      },
      serving: {
        visible: false,  // Now starts hidden until production happens
        completed: servingSeen
      },
      rating: {
        visible: false,  // Now starts hidden until serving happens
        completed: ratingSeen
      }
    };
  });

  const SPD = 300;
  const vh = Math.floor((time % SPD) / (SPD / 24));
  const dt = (() => {
    let hours = vh;
    const minutes = Math.floor((time % (SPD/24)) / (SPD/24/60));
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`;
  })();
  const isOpen = vh >= 6 && vh < 18;  // Changed from 7 to 6
  const isBaking = vh >= 4 && vh < 18;  // Changed from 2 to 4
  const cf = useCallback(() => {
    if (!isOpen) return 0;
    const baseFrequency = vh >= 7 && vh < 9 ? 8 : vh >= 12 && vh < 14 ? 5 : 6;
    const ratingMultiplier = (0.2 + ((rating/10) * 0.36));
    const dayMultiplier = 1 + (Math.min(day - 1, 9) * 0.2);
    return Math.round(baseFrequency * ratingMultiplier * dayMultiplier);
  }, [isOpen, vh, rating, day]);

  const startNewDay = () => {
    // Initialize or update game state when starting a day
    const gameState = JSON.parse(localStorage.getItem('gameState')) || {
      day,
      money,
      rating,
      totalCustomersServed,
      allTimeStats: {
        totalRevenue: 0,
        totalCustomers: 0,
        totalLostCustomers: 0
      }
    };
    
    gameState.inProgress = true;
    localStorage.setItem('gameState', JSON.stringify(gameState));
    
    setTime(4 * (SPD/24));  // Changed from 2 to 4
    setWaitingForStart(false);
    if (!firstDay) {
      setDay(p => p + 1);  // Only increment day if it's not the first day
    }
    setProducts({
      brot: {amount: 0, inProduction: 0, productionTimes: []},
      kuchen: {amount: 0, inProduction: 0, productionTimes: []},
      breze: {amount: 0, inProduction: 0, productionTimes: []}
    });
    setCustomers([]);
    setDailyStats({
      satisfiedCustomers: 0,
      lostCustomers: 0,
      productionCosts: 0,
      revenue: 0,
      soldProducts: {
        brot: 0,
        kuchen: 0,
        breze: 0
      },
      wastedProducts: {
        brot: 0,
        kuchen: 0,
        breze: 0
      },
      initialRating: rating / 10,  // Store initial rating as decimal
      initialBudget: money   // Store current money as initial
    });
    setFirstDay(false);
  };

  const closeDay = useCallback(() => {
    const wastedProducts = {...products};
    const dayStats = {
      ...dailyStats,
      wastedProducts: {
        brot: wastedProducts.brot.amount,
        kuchen: wastedProducts.kuchen.amount,
        breze: wastedProducts.breze.amount
      },
      ratingChange: (rating / 10) - dailyStats.initialRating,
      budgetChange: money - dailyStats.initialBudget
    };
  
    // Save game state to localStorage with inProgress set to false during day break
    const gameState = {
      inProgress: false,  // Changed to false since player is in day break
      day,
      money,
      rating,
      totalCustomersServed,
      dailyStats: dayStats,
      allTimeStats: {
        totalRevenue: (JSON.parse(localStorage.getItem('gameState'))?.allTimeStats?.totalRevenue || 0) + dayStats.revenue,
        totalCustomers: (JSON.parse(localStorage.getItem('gameState'))?.allTimeStats?.totalCustomers || 0) + dayStats.satisfiedCustomers,
        totalLostCustomers: (JSON.parse(localStorage.getItem('gameState'))?.allTimeStats?.totalLostCustomers || 0) + dayStats.lostCustomers
      }
    };
    localStorage.setItem('gameState', JSON.stringify(gameState));
    
    setDailyStats(dayStats);
    setWaitingForStart(true);
    
    const summary = {
      revenue: dayStats.revenue,
      costs: dayStats.productionCosts,
      profit: dayStats.revenue - dayStats.productionCosts,
      satisfiedCustomers: dayStats.satisfiedCustomers,
      lostCustomers: dayStats.lostCustomers,
      soldProducts: dayStats.soldProducts,
      wastedProducts: dayStats.wastedProducts,
      ratingChange: rating - dayStats.initialRating,
      budgetChange: money - dayStats.initialBudget
    };
    
    console.log('Day Summary:', summary);
  }, [dailyStats, money, products, rating, day, totalCustomersServed]);

  useEffect(() => {
    if (waitingForStart) return;
    const timer = setInterval(() => {
      setTime(p => {
        const n = p + 1;
        const currentVh = Math.floor((n % SPD) / (SPD / 24));
        if (currentVh >= 18) {
          closeDay();
        }
        return n;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [waitingForStart, closeDay, SPD]);

  useEffect(() => {
    if (waitingForStart || firstDay || gameOver) return;
      
    if (isOpen && time > 0) {
      const baseFrequency = cf();
      const shouldSpawn = Math.random() < (baseFrequency / 100);
      
      if (shouldSpawn) {
        
        const nc = Array(Math.floor(Math.random() * 3) + 1).fill(null).map(() => ({
          id: Math.random(),
          wants: Object.keys(P)[Math.floor(Math.random() * 3)],
          patience: Math.floor(Math.random() * 11) + 10,
          arrivalTime: time,
          ratingLost: false
        }));
        
        setCustomers(p => [...p, ...nc]);
      }
    }
  }, [time, isOpen, vh, cf, waitingForStart, firstDay, gameOver]); // Added missing dependencies

  const processingRef = React.useRef(new Set());

  const processLeavingCustomers = useCallback((customers) => {
    const leavingCustomers = customers.filter(c => c.patience <= 0 && !c.ratingLost);
    
    if (leavingCustomers.length > 0) {
      const batchKey = leavingCustomers.map(c => c.id).join(',');
      
      if (!processingRef.current.has(batchKey)) {
        processingRef.current.add(batchKey);

        // Immediately update stats and rating
        setDailyStats(prev => ({
          ...prev,
          lostCustomers: prev.lostCustomers + leavingCustomers.length
        }));
        
        setRating(prev => {
          const deduction = Math.min(prev, 5 * leavingCustomers.length);
          return Math.max(0, prev - deduction);
        });

        // Mark customers as rating lost and remove them after animation
        const updatedCustomers = customers.map(c => 
          leavingCustomers.find(lc => c.id === lc.id)
            ? { ...c, ratingLost: true }
            : c
        );

        setTimeout(() => {
          setCustomers(prev => prev.filter(c => !leavingCustomers.find(lc => c.id === lc.id)));
          processingRef.current.delete(batchKey);
        }, 1000);

        return updatedCustomers;
      }
    }
    
    return customers.map(c => 
      c.ratingLost ? c : { ...c, patience: Math.max(0, c.patience - 1) }
    );
  }, []);

  useEffect(() => {
    if (waitingForStart || firstDay || gameOver) return; // Add this check
    setCustomers(prevCustomers => processLeavingCustomers(prevCustomers));
  }, [time, processLeavingCustomers, waitingForStart, firstDay, gameOver]);

  useEffect(() => {
    const updateProductionTimes = () => {
      Object.entries(products).forEach(([p, i]) => {
        if (i.productionTimes.length > 0) {
          const ut = i.productionTimes.map(t => t - 1);
          const f = ut.filter(t => t <= 0).length;
          if (f > 0) {
            setProducts(prev => ({...prev, [p]: {
              amount: prev[p].amount + (P[p].b * f),
              inProduction: prev[p].inProduction - f,
              productionTimes: ut.filter(t => t > 0)
            }}));
          } else {
            setProducts(prev => ({...prev, [p]: {...prev[p], productionTimes: ut}}));
          }
        }
      });
    };

    updateProductionTimes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (rating <= 0 && !gameOver) {
      const gameState = JSON.parse(localStorage.getItem('gameState')) || {};
      setGameOver(true);
      setFinalStats({
        daysSurvived: day,
        servedCustomers: gameState.allTimeStats?.totalCustomers || totalCustomersServed,
        lostCustomers: gameState.allTimeStats?.totalLostCustomers || 0,
        totalRevenue: gameState.allTimeStats?.totalRevenue || 0,
        moneyEarned: money - STARTING_BUDGET
      });
      // Simply remove game state when game is over
      localStorage.removeItem('gameState');
    }
  }, [rating, day, totalCustomersServed, money, gameOver]);

  const startProduction = (p) => {
    if (money >= P[p].c && isBaking) {
      setMoney(prev => prev - P[p].c);
      setDailyStats(prev => ({
        ...prev,
        productionCosts: prev.productionCosts + P[p].c
      }));
      setProducts(prev => ({...prev, [p]: {
        ...prev[p],
        inProduction: prev[p].inProduction + 1,
        productionTimes: [...prev[p].productionTimes, P[p].t]
      }}));
      if (!hasProduced && !tooltips.serving.completed) {
        setHasProduced(true);
        setTooltips(prev => ({
          ...prev,
          serving: { ...prev.serving, visible: true }
        }));
      }
    }
  };

  const handleServeCustomer = (customerId, productType) => {
    const customer = customers.find(c => c.id === customerId);
    if (customer && customer.wants === productType && products[productType].amount > 0) {
      // Immediately update product amount, money, and stats
      setProducts(p => ({
        ...p,
        [productType]: {
          ...p[productType],
          amount: p[productType].amount - 1
        }
      }));
      setMoney(p => p + P[productType].p);
      setDailyStats(p => ({
        ...p,
        revenue: p.revenue + P[productType].p,
        satisfiedCustomers: p.satisfiedCustomers + 1,
        soldProducts: {
          ...p.soldProducts,
          [productType]: p.soldProducts[productType] + 1
        }
      }));
      setRating(prev => Math.min(50, prev + 1));
      setTotalCustomersServed(prev => prev + 1);

      // Mark customer as earned and remove after animation
      setCustomers(prev => prev.map(c => 
        c.id === customerId 
          ? {...c, earned: P[productType].p}
          : c
      ));

      setTimeout(() => {
        setCustomers(p => p.filter(c => c.id !== customerId));
        if (!hasServed && !tooltips.rating.completed) {
          setHasServed(true);
          setTooltips(prev => ({
            ...prev,
            rating: { ...prev.rating, visible: true }
          }));
        }
      }, 1000);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);
    
    if (!over) return;

    const draggedProductType = active.data.current.productType;
    const customerId = over.id;
    
    const customer = customers.find(c => c.id === customerId);
    const isValidDrop = customer && 
      customer.wants === draggedProductType && 
      products[draggedProductType].amount > 0;

    if (isValidDrop) {
      setCustomers(prev => prev.map(c => 
        c.id === customerId 
          ? {...c, earned: P[draggedProductType].p}
          : c
      ));
      handleServeCustomer(customerId, draggedProductType);
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5, // 5px of movement required before drag starts
    },
  });
  
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100, // 100ms delay before drag starts
      tolerance: 5, // 5px of movement allowed before drag cancels
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  // Update dismissTooltip to handle sequence
  const dismissTooltip = (name) => {
    localStorage.setItem(`${name}_tooltip_seen`, 'true');
    setTooltips(prev => ({
      ...prev,
      [name]: { visible: false, completed: true }
    }));
    // Remove the automatic showing of next tooltips from here
    // They will only show when the actual actions happen
  };

  // Add new effect to check saved game on mount
  useEffect(() => {
    const savedGame = JSON.parse(localStorage.getItem('gameState'));
    if (savedGame?.inProgress === true) {
      // Clean up interrupted games
      localStorage.removeItem('gameState');
    }
  }, []);

  const loadSavedGame = () => {
    const savedGame = JSON.parse(localStorage.getItem('gameState'));
    if (savedGame && !savedGame.inProgress) {
      setMoney(savedGame.money);
      setDay(savedGame.day + 1); // Increment day since we're starting the next day
      setRating(savedGame.rating);
      setTotalCustomersServed(savedGame.totalCustomersServed);
      setFirstDay(false);
      startNewDay();
    }
  };

  return (
    <>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        modifiers={[restrictToWindowEdges]}
      >
        <Card className="w-full max-w-2xl mx-auto sm:mt-5 relative sm:border sm:rounded-lg border-0 bg-stone-50/30">
          <CardHeader>
            <CardTitle className="flex justify-between items-center">
              <div className="flex flex-col">
                <span className="text-xxl">Bake Rush</span>
                <span className="text-sm text-gray-500">Day {day}</span>
              </div>
              <div className="flex flex-col items-end">
                <span className="flex items-center gap-2">💰 ${money}</span>
                <span className="flex items-center gap-1">
                  {Array.from({ length: 5 }).map((_, i) => (
                    <Star key={i} className={i < Math.round(rating / 10) ? "fill-yellow-400 text-yellow-400" : "text-gray-300"} size={16} />
                  ))}
                  <span className="text-sm text-gray-500">({(rating / 10).toFixed(1)})</span>
                </span>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              {gameOver ? (
                <div className="text-center p-6 bg-stone-100 rounded-lg outline outline-red-500">
                  <h3 className="font-bold text-xl mb-3">Bakery Closed</h3>
                  <p className="mb-4">Unfortunately, you could no longer meet your customers' wishes.</p>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-left mb-6">
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Business Statistics</h4>
                      <div className="space-y-1">
                        <p className="flex items-center gap-2">📅 <span className="font-medium">{finalStats.daysSurvived}</span> days open</p>
                        <p className="flex items-center gap-2">👥 <span className="font-medium">{finalStats.servedCustomers}</span> customers visited</p>
                        <p className="flex items-center gap-2">😢 <span className="font-medium">{finalStats.lostCustomers}</span> customers lost</p>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Financial Result</h4>
                      <div className="space-y-1">
                        <p>💰 Total Revenue: ${finalStats.totalRevenue}</p>
                        <p className={`font-medium ${finalStats.moneyEarned >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          Final Profit: {finalStats.moneyEarned >= 0 ? '+$' : '-$'}{Math.abs(finalStats.moneyEarned)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
                    <p className="text-gray-600 text-sm italic">
                      "The art of baking lies not only in the ingredients,
                      but also in the ability to fulfill customers' wishes."
                    </p>
                  </div>

                  <Button
                    onClick={() => window.location.reload()}
                    className="bg-stone-800 hover:bg-stone-900 text-white transition-colors"
                  >
                    Try Again
                  </Button>
                </div>
              ) : firstDay ? (
                <div className="text-center p-8 bg-stone-100 rounded-lg">
                  <h2 className="text-xl font-bold mb-3">Ready, Set, Bake!</h2>
                  <div className="mb-8 space-y-3">
                    <p>Run your own bakery and survive the rush.</p>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <span className="text-3xl block mb-2">🥨</span>
                      <p className="text-sm text-stone-600">Bake bread and pastries</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <span className="text-3xl block mb-2">⭐️</span>
                      <p className="text-sm text-stone-600">Make customers happy</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <span className="text-3xl block mb-2">💰</span>
                      <p className="text-sm text-stone-600">Generate financial profit</p>
                    </div>
                  </div>

                  {(() => {
                    const savedGame = JSON.parse(localStorage.getItem('gameState'));
                    if (savedGame && !savedGame.inProgress) {
                      return (
                        <div className="mb-8 space-y-3">
                          <div className="flex gap-2 justify-center">
                            <Button
                              onClick={loadSavedGame}
                              className="bg-stone-800 hover:bg-stone-900 text-white transition-colors"
                            >
                              Continue (Day {savedGame.day+1})
                            </Button>
                            <Button
                              onClick={() => {
                                localStorage.removeItem('gameState');
                                startNewDay();
                              }}
                              variant="outline"
                              className="border-stone-800 text-stone-800 hover:bg-stone-50"
                            >
                              New Game
                            </Button>
                          </div>
                        </div>
                      );
                    }
                      return (
                        <>
                          <div className="bg-white p-4 rounded-lg shadow-sm mb-8">
                            <p className="font-medium">Your starting capital: ${STARTING_BUDGET}</p>
                          </div>
                          <Button
                            onClick={startNewDay}
                            className="bg-stone-800 hover:bg-stone-900 text-white transition-colors"
                          >
                            Open Bakery
                          </Button>
                        </>
                      );
                    })()}


                </div>
              ) : waitingForStart ? (
                <div className="text-center p-6 bg-stone-100 rounded-lg">
                  <h3 className="font-bold text-xl mb-3">End of Day</h3>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-left mb-6">
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Customer Service</h4>
                      <div className="space-y-1">
                        <p>✅ Satisfied Customers: {dailyStats.satisfiedCustomers}</p>
                        <p>👥 Lost Customers: {dailyStats.lostCustomers}</p>
                        <p className={`font-medium ${dailyStats.ratingChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          ⭐️ Rating: {dailyStats.ratingChange >= 0 ? '+' : ''}{dailyStats.ratingChange.toFixed(1)}
                        </p>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Finances</h4>
                      <div className="space-y-1">
                        <p>📈 Revenue: ${dailyStats.revenue}</p>
                        <p>📉 Expenses: ${dailyStats.productionCosts}</p>
                        <p className={`font-medium ${(dailyStats.revenue - dailyStats.productionCosts) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          💰 Balance: {(dailyStats.revenue - dailyStats.productionCosts) >= 0 ? '+$' : '-$'}
                          {Math.abs(dailyStats.revenue - dailyStats.productionCosts)}
                        </p>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Products Sold</h4>
                      <div className="space-y-1">
                        <p>🥖 Bread: {dailyStats.soldProducts.brot} pcs</p>
                        <p>🎂 Cake: {dailyStats.soldProducts.kuchen} pcs</p>
                        <p>🥨 Pretzel: {dailyStats.soldProducts.breze} pcs</p>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h4 className="font-semibold mb-2">Wasted</h4>
                      <div className="space-y-1">
                        <p>🥖 Bread: {dailyStats.wastedProducts.brot} pcs</p>
                        <p>🎂 Cake: {dailyStats.wastedProducts.kuchen} pcs</p>
                        <p>🥨 Pretzel: {dailyStats.wastedProducts.breze} pcs</p>
                      </div>
                    </div>
                  </div>

                  <Button onClick={startNewDay} className="mt-4 bg-stone-800 hover:bg-stone-900 text-white transition-colors">
                    Start New Day
                  </Button>
                </div>
              ) : (
                <>
                        <div className="text-center p-2 rounded-lg bg-stone-100 px-4 py-3 border border-stone-200">
                          <div className="font-bold">{dt}</div>
                          <div className="text-sm">
                            {!isOpen && !isBaking && "Closed"}
                            {!isOpen && isBaking && "Baking Time (Store Closed)"}
                            {isOpen && "Open for Business"}
                          </div>
                        </div>
                  <div className="space-y-2">
                    <div className="border border-stone-200 rounded-lg p-4 bg-white">
                      <h3 className="font-bold mb-2">Waiting Customers: {customers.length}</h3>
                      <div className="flex flex-wrap gap-2 h-[90px] overflow-y-auto">
                        {customers.map(c => (
                          <DroppableCustomer
                            key={c.id}
                            customer={c}
                            onServe={handleServeCustomer}
                            productData={P}
                            currentStock={products}  // Add this prop
                            earned={c.earned}
                            ratingLost={c.ratingLost}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {Object.entries(P).map(([p, i]) => (
                      <Card key={p} className="p-4 border-stone-200 hover:border-stone-300 transition-colors">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center justify-between gap-4">
                            <div className="flex items-center gap-4">
                              <DraggableProduct
                                type={p}
                                amount={products[p].amount}
                                emoji={i.e}
                                isDragging={p === activeId}
                              />
                              <div className="flex-1">
                                <div className="text-sm">{products[p].amount} in stock</div>
                                <div className="text-sm">{products[p].inProduction * i.b} in oven</div>
                              </div>
                            </div>
                            <div className="sm:hidden">
                              <Button
                                onClick={() => startProduction(p)}
                                disabled={money < i.c || !isBaking}
                                className="h-auto py-1 whitespace-nowrap bg-stone-800 hover:bg-stone-900 text-white transition-colors"
                              >
                                {i.b}× (-${i.c})
                              </Button>
                            </div>
                          </div>
                          <Button
                            onClick={() => startProduction(p)}
                            disabled={money < i.c || !isBaking}
                            className="hidden sm:block w-full bg-stone-800 hover:bg-stone-900 text-white transition-colors"
                          >
                            {i.b} pieces (-${i.c})
                          </Button>
                          {products[p].productionTimes.length > 0 && (
                            <div className="text-xs space-y-1">
                              {products[p].productionTimes.map((t, idx) => (
                                <div key={idx} className="bg-stone-100 rounded-full p-1 text-center">Ready in {t}s</div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>

                  {/* Add tooltips */}
                  <Tooltip
                    visible={tooltips.baking.visible}
                    onDismiss={() => dismissTooltip('baking')}
                    position="bottom-[100px] left-4"
                  >
                    Click the buttons to bake new goods.
                    Each production costs money and takes time.
                  </Tooltip>

                  <Tooltip
                    visible={tooltips.serving.visible}
                    onDismiss={() => dismissTooltip('serving')}
                    position="top-[150px] right-4"
                  >
                    Your customers will appear here.
                    Once the goods are ready, you can drag them to matching customers.
                  </Tooltip>

                  <Tooltip
                    visible={tooltips.rating.visible}
                    onDismiss={() => dismissTooltip('rating')}
                    position="top-[20px] right-4"
                  >
                    Satisfied customers bring money, dissatisfied customers lower your rating.
                    Good luck! 🌟
                  </Tooltip>
                </>
              )}
            </div>
          </CardContent>
        </Card>
<div className="w-full max-w-2xl mx-auto text-center mt-4 mb-8">
    <div>
    <a
      href="https://www.maxwallner.com"
      className="text-sm text-stone-500 hover:text-stone-700 transition-colors"
      target="_blank"
      rel="noopener noreferrer"
    >
      Created by Max Wallner
    </a>
  </div>
  <div className="space-y-2">
    <a
      href="https://www.maxwallner.com/imprint/"
      className="text-sm text-stone-500 hover:text-stone-700 transition-colors"
      target="_blank"
      rel="noopener noreferrer"
    >
      Imprint & Privacy Policy
    </a>
  </div>
</div>

        <DragOverlay>
          {activeId ? <DragPreview emoji={P[activeId].e} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default BakeryGame;